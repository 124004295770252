@import "common.mix.less";
@body: black;
@link: #34345c;
@em: #789922;
@del: black;
@postBG: #d6daf0;
@border: 1px solid #b7c5d9;
@bold: #117743;
@admin: #f00000;
@mod: purple;
@red: @admin;
@blue: #1048ff;

a:hover, blockquote a {
	color: #d00;
}

article, aside {
	background-color: @postBG;
	border-right: @border;
	border-bottom: @border;
}

article {
	article {
		border: @border;
	}
	&.highlight:not(.editing), &:target {
		background-color: #d6bad0;
		border-color: #ba9dbf;
		article {
			border: solid 1px #ba9dbf;
		}
	}
	&.editing {
		background-color: #fa6;
		border-color: #e95;
		article {
			border: solid 1px #e95;
		}
	}
}

body {
	background-color: #eef2ff;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 10pt;
}

h1 {
	color: #af0a0f;
	font: bolder 28px Tahoma;
	letter-spacing: -2px;
}

h2 {
	color: #af0a0f;
	font-family: Tahoma;
}

h3 {
	color: #0f0c5d;
}

header nav a {
	color: black;
}

hr {
	border-top: 1px solid #b7c5d9;
}

.popup-menu li:hover {
	background-color: #f5f5f5;
}

.modal, .preview:not(.editing), .popup-menu {
	background-color: white;
	border-right: 1px solid #eee;
	border-bottom: 1px solid #eee;
}

a.tab-sel {
	color: #117743;
}

.omit {
	color: gray;
}
